<template>
  <v-container
    fluid
    style="background-color: #E5E5E5;"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="mb-0 pb-0 pr-0"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Aset"
          color="purple"
          single-line
          hide-details
          clearable
          outlined
          dense
          background-color="white"
          @keyup.enter.native="filterData"
          @click:append="filterData"
          @click:clear="filterClear"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        class="mb-n4 pb-0"
      >
        <div class="d-inline-flex">
          <v-select
            v-model="filterStatus"
            class="ml-2 mr-3 custom-select"
            :items="filterStatusItems"
            dense
            outlined
            background-color="white"
            item-text="itemText"
            item-value="itemValue"
            color="purple"
            :menu-props="{ bottom: true, offsetY: true }"
            item-color="purple"
            @change="filterByStatus"
          />

          <v-select
            v-model="filterPrice"
            class="ml-2 custom-select"
            :items="filterPriceItems"
            dense
            outlined
            background-color="white"
            item-text="itemText"
            item-value="itemValue"
            color="purple"
            :menu-props="{ bottom: true, offsetY: true }"
            item-color="purple"
            return-object
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="5"
        class="text-right mb-n2 pb-0"
      />
    </v-row>

    <v-row style="margin-left: 2px; margin-right: 2px;">
      <v-col
        class="px-0 py-0"
        cols="12"
        sm="6"
        md="3"
        style="background-color: #FFFFFF;"
      >
        <div class="container-header">
          <v-row
            v-for="(asset, i) in assets"
            :id="'asset-header-id-' + i"
            :key="i"
            class="ml-0 pl-3 pr-5 py-0 my-0 asset-header"
            @click="assetHeaderClick(i, asset.hash, asset.status)"
          >
            <v-row>
              <v-col
                class="mb-0 pb-0"
                style="font-size: 12px;"
              >
                <span class="font-weight-bold">
                  {{ asset.prt_name }} - {{ asset.prt_name_legal }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-weight-bold">
                {{ asset.code }}
                <span class="mx-2">
                  &#9679;
                </span>
                {{ asset.type }}
                <span class="mx-2">
                  &#9679;
                </span>
                {{ asset.width }} X {{ asset.height }} M
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                {{ asset.add_top }}
                {{ asset.add_bottom }}
              </v-col>
            </v-row>

            <v-row>
              <v-col v-if="filterPrice.itemValue === 'daily'">
                Rp {{ asset.price_day || 0 | dotThousandSeparator }} / Hari
              </v-col>
              <v-col v-if="filterPrice.itemValue === 'monthly'">
                Rp {{ asset.price_month || 0 | dotThousandSeparator }} / Bulan
              </v-col>
              <v-col v-if="filterPrice.itemValue === 'yearly'">
                Rp {{ asset.price_year || 0 | dotThousandSeparator }} / Tahun
              </v-col>
              <v-col
                class="text-right"
                :style="getColor(asset.status_color)"
                style="font-weight: bold; font-style: italic; font-size: 12px;"
              >
                {{ asset.status_string }}
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>

      <v-col
        v-if="showAssetDetail"
        style="background-color: #F2F2F2;"
        cols="12"
        sm="6"
        md="9"
      >
        <div class="container-detail">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <span class="font-weight-bold text-h4">
                {{ assetDetail.prt_name }} - {{ assetDetail.prt_name_legal }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              Terakhir Disewa <span class="font-weight-bold">{{ assetDetail.last_rented_date }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="1"
            >
              <v-icon color="black">
                mdi-heart
              </v-icon>
              <span class="ml-1 font-weight-bold">{{ assetDetail.count_fav }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="px-5 py-5 mt-0 mb-0">
                <v-row>
                  <v-col class="font-weight-bold">
                    Informasi Umum
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0 text--secondary">
                    Kode Internal
                  </v-col>
                  <v-col class="py-0 text--secondary">
                    Tipe
                  </v-col>
                  <v-col class="py-0 text--secondary">
                    Venue
                  </v-col>
                  <v-col class="py-0 text--secondary">
                    Pencahayaan
                  </v-col>
                  <v-col class="py-0 text--secondary">
                    Potensi Lalu Lintas
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="font-weight-bold text--secondary">
                    {{ assetDetail.internal_code }}
                  </v-col>
                  <v-col class="font-weight-bold text--secondary">
                    {{ assetDetail.type }}
                  </v-col>
                  <v-col class="font-weight-bold text--secondary">
                    {{ assetDetail.venue }}
                  </v-col>
                  <v-col class="font-weight-bold text--secondary">
                    {{ assetDetail.light }}
                  </v-col>
                  <v-col>
                    <!-- Car Active: 1 -->
                    <template
                      v-if="assetDetail.traffic_code === 1"
                    >
                      <v-icon color="purple">
                        mdi-car
                      </v-icon>
                      <v-icon>
                        mdi-car
                      </v-icon>
                      <v-icon>
                        mdi-car
                      </v-icon>
                    </template>

                    <!-- Car Active: 2 -->
                    <template
                      v-if="assetDetail.traffic_code === 2"
                    >
                      <v-icon color="purple">
                        mdi-car
                      </v-icon>
                      <v-icon color="purple">
                        mdi-car
                      </v-icon>
                      <v-icon>
                        mdi-car
                      </v-icon>
                    </template>

                    <!-- Car Active: 3 -->
                    <template
                      v-if="assetDetail.traffic_code === 3"
                    >
                      <v-icon color="purple">
                        mdi-car
                      </v-icon>
                      <v-icon color="purple">
                        mdi-car
                      </v-icon>
                      <v-icon color="purple">
                        mdi-car
                      </v-icon>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="px-5 py-5 mt-0 mb-0">
                <v-row>
                  <v-col class="font-weight-bold">
                    Harga Media
                  </v-col>
                </v-row>

                <!-- Print and Install -->
                <v-row>
                  <v-col class="py-0 text--secondary">
                    Biaya Cetak
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.price_print || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0 text--secondary">
                    Biaya Pasang
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.price_install || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                </v-row>

                <v-divider class="mt-3 mb-3" />

                <!-- Header -->
                <v-row>
                  <v-col class="py-0 text--secondary" />
                  <v-col class="py-0 text--secondary">
                    Sewa Media
                  </v-col>
                  <v-col class="py-0 text--secondary">
                    Pajak Reklame
                  </v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col class="mb-1">
                    <v-spacer />
                  </v-col>
                </v-row>

                <!-- Yearly -->
                <v-row>
                  <v-col class="py-0 text--secondary">
                    Harga Tahunan
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.price_year || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.ads_tax_year || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                </v-row>

                <!-- Monthly -->
                <v-row>
                  <v-col class="py-0 text--secondary">
                    Harga Bulanan
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.price_month || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.ads_tax_month || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                </v-row>

                <!-- Daily -->
                <v-row>
                  <v-col class="py-0 text--secondary">
                    Harga Harian
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.price_day || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col class="py-0 text--secondary font-weight-bold">
                    Rp {{ assetDetail.ads_tax_month || 0 | dotThousandSeparator }}
                  </v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                </v-row>

                <v-divider class="mt-5 mb-3" />

                <v-row>
                  <v-col class="font-weight-bold">
                    Fluktuasi Harga
                  </v-col>
                  <!-- <v-col class="text-right">
                    <span style="color: #EA3E3A;">&#9899;</span> Harian
                    <span
                      class="ml-3"
                      style="color: #8FABE0;"
                    >&#9899;</span> Bulanan
                    <span
                      class="ml-3"
                      style="color: #FFC709;"
                    >&#9899;</span> Tahunan
                  </v-col> -->
                </v-row>

                <v-row>
                  <v-col>
                    <line-chart
                      :chart-data="chartData"
                      :options="chartOptions"
                      style="height: 200px;"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="px-5 py-5 mt-0 mb-0">
                <v-row>
                  <v-col class="font-weight-bold">
                    Lokasi Media
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <v-col class="text--secondary">
                        Alamat
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 font-weight-bold text--secondary">
                        {{ assetDetail.ass_address_complete }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="text--secondary mt-5">
                        Tampak
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 font-weight-bold text--secondary">
                        {{ assetDetail.view_point }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col>
                    <GmapMap
                      :center="mapCoordinate"
                      :zoom="15"
                      map-type-id="roadmap"
                      style="width: auto; height: 300px;"
                      :options="mapOptions"
                    >
                      <GmapMarker
                        :position="mapCoordinate"
                      />
                    </GmapMap>
                    <a
                      target="_blank"
                      :href="'https://www.google.com/maps/search/?api=1&query=' + mapCoordinate.lat + ',' + mapCoordinate.lng"
                    >
                      Buka peta di Google Maps
                    </a>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card
                class="px-5 py-5 mt-0 mb-0"
              >
                <v-row>
                  <v-col class="px-5 py-5 mt-0 mb-0 font-weight-bold">
                    Foto Media
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <template v-if="assetDetail.photos.length > 0">
                      <tiny-slider
                        ref="tinySlider"
                        v-bind="tinySliderOptions"
                      >
                        <div
                          v-for="(photo, i) in assetDetail.photos"
                          :key="i"
                        >
                          <v-img
                            :src="photo.link"
                            style="height: 100%;"
                          />
                        </div>
                      </tiny-slider>

                      <template v-if="assetDetail.photos.length > 3">
                        <div class="d-flex justify-space-between arrow">
                          <v-icon
                            x-large
                            style="color: #F2F2F2;"
                            @click="prev"
                          >
                            mdi-chevron-left
                          </v-icon>
                          <v-icon
                            x-large
                            style="color: #F2F2F2;"
                            @click="next"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </div>

                        <div class="d-flex justify-center py-3">
                          <span
                            v-for="(n, i) in listImages"
                            :id="'dot-' + i"
                            :key="n"
                            class="dot"
                            @click="navigationClick(i)"
                          />
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      Belum ada foto
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="px-5 py-5 mt-0 mb-0">
                <v-row>
                  <v-col class="font-weight-bold">
                    Dokumen Media
                  </v-col>
                </v-row>

                <template v-if="assetDetail.documents">
                  <v-row>
                    <v-col class="mt-2">
                      <v-row>
                        <v-col class="text--secondary py-0">
                          {{ assetDetail.documents[0].name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text--secondary font-weight-bold py-3">
                          <div
                            class="d-inline-flex"
                            style="cursor: pointer;"
                            @click="downloadDocument(assetDetail.documents[0].file_path)"
                          >
                            <template v-if="assetDetail.documents[0].file_name">
                              {{ assetDetail.documents[0].file_name }}
                              <v-icon class="ml-2">
                                mdi-arrow-down-bold-circle
                              </v-icon>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Status <span
                            class="font-italic font-weight-bold"
                            :style="getColor(assetDetail.documents[0].status_color)"
                          >{{ assetDetail.documents[0].status_string }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Perpanjangan pada  <span class="font-weight-bold font-italic">{{ assetDetail.documents[0].expired_date_format || '-' }}</span>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col class="mt-2">
                      <v-row>
                        <v-col class="text--secondary py-0">
                          {{ assetDetail.documents[1].name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text--secondary font-weight-bold py-3">
                          <div
                            class="d-inline-flex"
                            style="cursor: pointer;"
                            @click="downloadDocument(assetDetail.documents[0].file_path)"
                          >
                            <template v-if="assetDetail.documents[1].file_name">
                              {{ assetDetail.documents[1].file_name }}
                              <v-icon class="ml-2">
                                mdi-arrow-down-bold-circle
                              </v-icon>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Status <span
                            class="font-italic font-weight-bold"
                            :style="getColor(assetDetail.documents[1].status_color)"
                          >{{ assetDetail.documents[1].status_string }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Perpanjangan pada  <span class="font-weight-bold font-italic">{{ assetDetail.documents[1].expired_date_format || '-' }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="mt-2">
                      <v-row>
                        <v-col class="text--secondary py-0">
                          {{ assetDetail.documents[2].name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text--secondary font-weight-bold py-3">
                          <div
                            class="d-inline-flex"
                            style="cursor: pointer;"
                            @click="downloadDocument(assetDetail.documents[0].file_path)"
                          >
                            <template v-if="assetDetail.documents[2].file_name">
                              {{ assetDetail.documents[2].file_name }}
                              <v-icon class="ml-2">
                                mdi-arrow-down-bold-circle
                              </v-icon>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Status <span
                            class="font-italic font-weight-bold"
                            :style="getColor(assetDetail.documents[2].status_color)"
                          >{{ assetDetail.documents[2].status_string }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Perpanjangan pada  <span class="font-weight-bold font-italic">{{ assetDetail.documents[2].expired_date_format || '-' }}</span>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col class="mt-2">
                      <v-row>
                        <v-col class="text--secondary py-0">
                          {{ assetDetail.documents[3].name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text--secondary font-weight-bold py-3">
                          <div
                            class="d-inline-flex"
                            style="cursor: pointer;"
                            @click="downloadDocument(assetDetail.documents[0].file_path)"
                          >
                            <template v-if="assetDetail.documents[3].file_name">
                              {{ assetDetail.documents[3].file_name }}
                              <v-icon class="ml-2">
                                mdi-arrow-down-bold-circle
                              </v-icon>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Status <span
                            class="font-italic font-weight-bold"
                            :style="getColor(assetDetail.documents[3].status_color)"
                          >{{ assetDetail.documents[3].status_string }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="text--secondary py-1"
                          style="font-size: 12px;"
                        >
                          Perpanjangan pada  <span class="font-weight-bold font-italic">{{ assetDetail.documents[3].expired_date_format || '-' }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>

                <template v-else>
                  <v-row>
                    <v-col>
                      Belum ada data
                    </v-col>
                  </v-row>
                </template>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="px-5 py-5 mt-0 mb-0">
                <v-row>
                  <v-col class="font-weight-bold">
                    Jadwal Pemeliharaan
                  </v-col>
                  <v-col class="text-right">
                  <!-- <v-btn
                    dark
                    color="primary"
                    class="mr-2 text-capitalize custom-buttom"
                    outlined
                  >
                    <b>Tambah Jadwal</b>
                  </v-btn> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <table class="maintenance-table">
                      <thead>
                        <tr>
                          <th class="text-left">
                            Pekerjaan
                          </th>
                          <!-- <th class="text-left">
                            Keterangan
                          </th> -->
                          <th class="text-left">
                            Tanggal
                          </th>
                        <!-- <th class="text-left" />
                        <th class="text-left" /> -->
                        </tr>
                      </thead>

                      <tbody v-if="dataMaintenance.length === 0">
                        <tr>
                          <td>
                            -
                          </td>
                          <!-- <td>
                            -
                          </td> -->
                          <td>
                            -
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-if="showMore">
                        <tr
                          v-for="(item, i) in dataMaintenance.slice(0, 3)"
                          :key="i"
                        >
                          <td :class="getRowStyle(i)">
                            {{ item.title }}
                          </td>
                          <!-- <td :class="getRowStyle(i)">
                            {{ item.title }}
                          </td> -->
                          <td :class="getRowStyle(i)">
                            {{ item.date }}
                          </td>
                        <!-- <td :class="getRowStyle(i)">
                          <v-icon style="cursor: pointer;">
                            mdi-pencil
                          </v-icon>
                        </td>
                        <td :class="getRowStyle(i)">
                          <v-icon style="cursor: pointer;">
                            mdi-trash-can
                          </v-icon>
                        </td> -->
                        </tr>
                      </tbody>

                      <tbody v-else>
                        <tr
                          v-for="(item, i) in dataMaintenance"
                          :key="i"
                        >
                          <td>
                            {{ item.title }}
                          </td>
                          <!-- <td>
                            {{ item.title }}
                          </td> -->
                          <td>
                            {{ item.date }}
                          </td>
                          <!-- <td>
                            <v-icon style="cursor: pointer;">
                              mdi-pencil
                            </v-icon>
                          </td>
                          <td>
                            <v-icon style="cursor: pointer;">
                              mdi-trash-can
                            </v-icon>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-inline-flex justify-center align-center">
                    <div
                      v-if="showMore"
                      style="cursor: pointer;"
                      @click="changeShowMore"
                    >
                      <v-icon large>
                        mdi-chevron-down
                      </v-icon>
                      <span
                        class="font-weight-bold"
                        style="color: #808080;"
                      >
                        Lihat Semua
                      </span>
                    </div>
                    <div
                      v-else
                      style="cursor: pointer;"
                      @click="changeShowMore"
                    >
                      <v-icon large>
                        mdi-chevron-up
                      </v-icon>
                      <span
                        class="font-weight-bold"
                        style="color: #808080;"
                      >
                        Perkecil
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="px-5 py-5 mt-0 mb-0">
                <v-row>
                  <v-col class="font-weight-bold">
                    Pesanan Offline
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <table class="maintenance-table">
                      <thead>
                        <tr>
                          <th class="text-left">
                            Tanggal Mulai
                          </th>
                          <th class="text-left">
                            Durasi
                          </th>
                          <th class="text-left">
                            Nama
                          </th>
                          <th class="text-left">
                            Perusahaan
                          </th>
                          <th class="text-left">
                            Telepon
                          </th>
                        </tr>
                      </thead>

                      <tbody v-if="assetDetail.offline_order.length === 0">
                        <tr>
                          <td>
                            -
                          </td>
                          <td>
                            -
                          </td>
                          <td>
                            -
                          </td>
                          <td>
                            -
                          </td>
                          <td>
                            -
                          </td>
                          <td>
                            -
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-if="assetDetail.offline_order.length > 0">
                        <tr
                          v-for="(item, i) in assetDetail.offline_order"
                          :key="i"
                        >
                          <td>
                            {{ item.start_date }}
                          </td>
                          <td>
                            {{ item.duration }}
                          </td>
                          <td>
                            {{ item.cust_name }}
                          </td>
                          <td>
                            {{ item.cust_company }}
                          </td>
                          <td>
                            {{ item.cust_phone }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn
                v-if="isEnableBlockButton"
                class="text-capitalize"
                color="white"
                @click="dialogBlockAsset = true"
              >
                <span :style="blockButtonStyle">
                  {{ blockButtonText }}
                </span>
                <!-- <span>
                  Buka Blokir
                </span> -->
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row>
          <v-col class="text-right">
            <v-btn
              dark
              color="red"
              class="mr-2 text-capitalize custom-buttom"
              outlined
            >
              <v-icon
                class="mr-2"
                color="red"
              >
                mdi-trash-can
              </v-icon>
              <b>Hapus Asset</b>
            </v-btn>
          </v-col>
        </v-row> -->
        </div>
      </v-col>

      <v-col
        v-else
        style="background-color: #F2F2F2;"
      >
        <div class="d-flex justify-center align-center align-content-center">
          Loading . . .
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <div
          class="d-flex justify-content-start"
        >
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            :total-visible="5"
            @input="onChangePage"
          />
          <!-- <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span> -->
        </div>

        <v-row class="mt-n12">
          <v-col class="text-right pr-8">
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col
        cols="12"
        sm="6"
        md="8"
      >
        <v-spacer />
      </v-col> -->
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="dialogBlockAsset"
        max-width="340"
      >
        <v-card>
          <v-card-text class="pb-2">
            <span style="font-size: 14px;">
              Apakah anda yakin akan memblokir aset ini ?
            </span>
          </v-card-text>
          <v-card-actions class="px-7 pt-1 pb-3">
            <v-row>
              <v-col>
                <v-btn
                  color="white"
                  class="text-capitalize"
                  small
                  block
                  @click="blockAsset"
                >
                  <span style="font-size: 14px;">
                    Ya
                  </span>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="purple"
                  class="text-capitalize"
                  small
                  block
                  @click="dialogBlockAsset = false"
                >
                  <span style="font-size: 14px;">
                    Tidak
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import LineChart from '../../component/line-vuechart.js'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: { LineChart },
    data: () => ({
      dialogBlockAsset: false,
      blockButtonText: 'Blokir Aset',
      blockButtonStyle: 'color: red; font-weight: bold;',

      search: '',
      headers: [
        { text: 'Kode', align: 'start', value: 'code', sortable: true },
        { text: 'Nama', value: 'name', sortable: true },
        { text: 'Provinsi', value: 'add_pro', sortable: true },
        { text: 'Status', value: 'status_string', sortable: true },
        { text: '', value: 'actions', sortable: false },
      ],

      assets: [],
      assetDetail: [],
      isAllowVerification: false,
      isAllowDetail: true,
      showAssetDetail: false,
      isEnableBlockButton: false,
      isAllowEdit: false,

      // Section Pagination:
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      // Section Location:
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      },
      coordinate: {
        lat: -6.995092,
        long: 110.420914,
      },

      // Filter:
      filterPrice: { itemText: 'Harga Per Bulan', itemValue: 'monthly' },
      filterStatus: { itemText: 'Semua', itemValue: 0 },
      filterPriceItems: [
        { itemText: 'Harga Per Tahun', itemValue: 'yearly' },
        { itemText: 'Harga Per Bulan', itemValue: 'monthly' },
        { itemText: 'Harga Per Hari', itemValue: 'daily' },
      ],
      filterStatusItems: [
        { itemText: 'Semua', itemValue: 0 },
        { itemText: 'Aktif', itemValue: 1 },
        { itemText: 'Tidak Aktif', itemValue: 2 },
        { itemText: 'Dalam Peninjauan', itemValue: 3 },
        { itemText: 'Informasi Belum Sesuai', itemValue: 4 },
        { itemText: 'Draft', itemValue: 5 },
        { itemText: 'Terblokir', itemValue: 6 },
      ],

      // Chart Configurations :
      chartData: {
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          // display: false,
          position: 'top',
          align: 'end',
          labels: {
            usePointStyle: true,
          },
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return 'Tanggal : ' + tooltipItem[0].label
            },
            label: function (tooltipItem, data) {
              return 'Harga : Rp ' + tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            },
          },
        },
        scales: {
          xAxes: [{
            precision: 0,
            type: 'category',
            position: 'bottom',
            labels: ['January', 'February', 'March', 'April', 'May', 'June',
                     'July', 'August', 'September', 'October', 'November', 'Desember'],
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
          }],
          yAxes: [{
            // stacked: true,
            ticks: {
              precision: 0,
              padding: 3,
              autoSkip: false,
              callback: function (value, index, values) {
                return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              },
            },
          }],
        },
      },

      // Maintenance Section:
      showMore: true,
      dataMaintenance: [
        { a: 'Cek Lampu', b: 'Ganti Lampu', c: '10 Desember 2020' },
        { a: 'Cat Ulang', b: 'Cat Ulang', c: '12 Desember 2020' },
        { a: 'Perawatan Rutin', b: 'Perawatan Rutin', c: '15 Desember 2020' },
      ],

      // Photos Section:
      tinySliderOptions: {
        mouseDrag: true,
        loop: false,
        items: 3,
        controls: false,
      },
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      mapCoordinate () {
        const retVal = {}
        if (this.assetDetail.coordinate) {
          retVal.lat = this.assetDetail.coordinate.lat
          retVal.lng = this.assetDetail.coordinate.long
        } else {
          retVal.lat = this.coordinate.lat
          retVal.lng = this.coordinate.long
        }
        return retVal
      },

      listImages () {
        let imageCount = 0
        if (this.assetDetail.photos) {
          imageCount = this.assetDetail.photos.length
        }
        if (imageCount > 0) {
          imageCount = Math.ceil(imageCount / 3)
        }
        return imageCount
      },
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      async initialize (page, limit, filter, status) {
        // Check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('ASSET_VERIFICATION')) {
          this.isAllowVerification = true
        }
        if (userMenus.includes('ASSET_EDIT')) {
          this.isAllowEdit = true
        }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
          status: status,
        }

        await axios.post('/t/asset', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.assets = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        }).catch((e) => {
        })

        this.assetHeaderClick(0, vm.assets[0].hash, vm.assets[0].status)
      },

      async assetHeaderClick (i, assetHash, status) {
        this.resetActiveState()
        const element = document.getElementById('asset-header-id-' + i)
        element.classList.add('active')

        const vm = this

        const requestBody = {
          ass_hash: assetHash,
        }

        await axios.post('/t/asset/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            if ((status === 1 || status === 2 || status === 6) && this.isAllowEdit) this.isEnableBlockButton = true
            if (status === 6) {
              this.blockButtonStyle = 'font-weight: bold;'
              this.blockButtonText = 'Buka Blokir Aset'
            } else {
              this.blockButtonStyle = 'color: red; font-weight: bold;'
              this.blockButtonText = 'Blokir Aset'
            }
            vm.assetDetail = res.data.data.detail
            this.dataMaintenance = vm.assetDetail.maintenance_schedule
            this.showAssetDetail = true
            if (vm.assetDetail.price_fluctuation_day.length === 0) this.mappingChartData(0)
            if (vm.assetDetail.price_fluctuation_day.length > 0) this.mappingChartData(this.assetDetail)
          }
        }).catch((e) => {
        })

        // console.log(this.assetDetail)
      },

      resetActiveState () {
        this.showAssetDetail = false
        const assetLength = this.assets.length
        for (let i = 0; i < assetLength; i++) {
          const element = document.getElementById('asset-header-id-' + i)
          element.classList.remove('active')
        }
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      // editButtonClick (item) {
      //   this.$router.push({ name: 'AssetDetail', params: { id: item.hash } })
      // },

      verificationButtonClick () {
        this.$router.push({ name: 'AssetsVerification' })
      },

      getColor (color) {
        return 'color: #' + color
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterByStatus (status) {
        // 1: Aktif
        // 2: Tidak Aktif
        // 3: Dalam peninjauan
        // 4: Informasi Blm Sesuai
        // 5: Draft
        // 6: Blocked
        this.resetActiveState()
        this.initialize(1, this.itemsPerPage, '', status.toString())
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },

      getRowStyle (i) {
        let cssClass = ''

        if (i === 0) {
          cssClass = 'text--primary'
        }
        if (i === 1) {
          cssClass = 'text--secondary'
        }
        if (i === 2) {
          cssClass = 'text--disabled'
        }

        return cssClass
      },

      changeShowMore () {
        this.showMore = !this.showMore
      },

      downloadDocument (link) {
        window.open(link, '_blank')
      },

      prev () {
        this.$refs.tinySlider.slider.goTo('prev')
      },

      next () {
        this.$refs.tinySlider.slider.goTo('next')
      },

      navigationClick (i) {
        this.$refs.tinySlider.slider.goTo(i)
      },

      async blockAsset () {
        const requestBody = {
          asset_id: this.assetDetail.hash,
        }

        this.dialogBlockAsset = false

        await axios.post('/t/asset/mod/block', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.initialize(1, this.itemsPerPage)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      mappingChartData (data) {
        if (data === 0) {
          this.chartData.datasets = []
          this.chartData.datasets.push(
            // Daily
            {
              label: 'Harga',
              backgroundColor: 'rgba(234, 61, 58, 1)',
              borderColor: 'rgba(234, 61, 58, 1)',
              data: [1000000, 2000000, 3000000, 4000000, 2000000, 3000000],
              type: 'line',
              fill: false,
              borderWidth: 2,
              lineTension: 0,
              pointStyle: 'circle',
              steppedLine: true,
            },

            // Monthly
            {
              label: 'Harga',
              backgroundColor: 'rgba(143, 171, 224, 1)',
              borderColor: 'rgba(143, 171, 224, 1)',
              data: [2000000, 5000000, 6000000, 3000000, 1000000, 2000000],
              type: 'line',
              fill: false,
              borderWidth: 2,
              lineTension: 0,
              pointStyle: 'circle',
              steppedLine: true,
            },

            // Yearly
            {
              label: 'Harga',
              backgroundColor: 'rgba(255, 199, 9, 1)',
              borderColor: 'rgba(255, 199, 9, 1)',
              data: [1000000, 3000000, 2000000, 3000000, 6000000, 5000000],
              type: 'line',
              fill: false,
              borderWidth: 2,
              lineTension: 0,
              pointStyle: 'circle',
              steppedLine: true,
            },
          )
        } else {
          this.chartData.datasets = []
          const dataDaily = []
          const dataMonthly = []
          const dataYearly = []
          const xLabels = []

          data.price_fluctuation_day.map(val => {
            dataDaily.push(val.price)
          })

          data.price_fluctuation_month.map(val => {
            dataMonthly.push(val.price)
          })

          data.price_fluctuation_year.map(val => {
            dataYearly.push(val.price)
            xLabels.push((new Date(val.timestamp * 1000)).toDateString())
          })

          this.chartOptions.scales.xAxes[0].labels = xLabels

          this.chartData.datasets.push(
            // Daily
            {
              label: 'Harian',
              backgroundColor: 'rgba(234, 61, 58, 1)',
              borderColor: 'rgba(234, 61, 58, 1)',
              data: dataDaily,
              type: 'line',
              fill: false,
              borderWidth: 2,
              lineTension: 0,
              pointStyle: 'circle',
              steppedLine: true,
            },

            // Monthly
            {
              label: 'Bulanan',
              backgroundColor: 'rgba(143, 171, 224, 1)',
              borderColor: 'rgba(143, 171, 224, 1)',
              data: dataMonthly,
              type: 'line',
              fill: false,
              borderWidth: 2,
              lineTension: 0,
              pointStyle: 'circle',
              steppedLine: true,
            },

            // Yearly
            {
              label: 'Tahunan',
              backgroundColor: 'rgba(255, 199, 9, 1)',
              borderColor: 'rgba(255, 199, 9, 1)',
              data: dataYearly,
              type: 'line',
              fill: false,
              borderWidth: 2,
              lineTension: 0,
              pointStyle: 'circle',
              steppedLine: true,
            },
          )
        }
      },
    },
  }
</script>

<style scoped>
  .v-btn.v-size--default {
    font-size: 16px;;
  }

  .asset-header {
    cursor: pointer;
    border-bottom: 1px solid #F2F2F2;
  }

  .active {
    background-color: #F2F2F2;
  }

  .custom-buttom {
    border-radius: 4px;
    border-color: #e7e7e7;
    background-color: #FFFFFF;
  }

  .custom-select {
    width: 170px;
  }

  .v-input {
    font-size: 14px;
  }

  .maintenance-table {
    width: 100%;
    padding: 5px;
    table-layout: auto;
    /* border: 1px solid black; */
  }

  .maintenance-table thead tr th {
    padding: 10px;
    /* border: 1px solid black; */
  }

  .maintenance-table tbody tr td {
    padding: 10px;
    /* border: 1px solid black; */
  }

  .tns-item {
    /* font-size: 3rem;
    font-family: Arial;
    text-align: center;
    background:#fafafb; */
    padding: 5px;
    height: 250px;
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    padding-top: 10px;
  }

  .dot-active, .dot:hover {
    background-color: #717171;
  }

  /* Next & previous buttons */
  .arrow {
    cursor: pointer;
    position: absolute;
    top: 53%;
    color: white;
    width: 96%;
  }

  .container-header {
    overflow-y: auto !important;
    overflow-x: hidden;
    height: 1000px;
  }

  .container-header::-webkit-scrollbar {
    display: none;
  }

  .container-detail {
    overflow-y: auto !important;
    overflow-x: hidden;
    height: 1000px;
  }

  .container-detail::-webkit-scrollbar {
    display: none;
  }
</style>
